import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'transactionDateTime',
})
export class transactionDateTimePipe implements PipeTransform {
  constructor() {}

  transform(dateTime: string) {
    const date = moment(dateTime).format('DD.M.yyyy').toString();
    const time = moment(dateTime).format('HH:mm').toString();

    return date + ' в ' + time;
  }
}